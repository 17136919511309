import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'

// Query for the coaches content in Prismic
export const query = graphql`
{
  allPrismicCoach(sort:{fields:data___order, order: ASC}){
    edges{
      node{
        id
        type
        data {
          order
          name {
            html
            text
            raw
          }
          title {
            html
            text
            raw
          }
          rank {
            html
            text
            raw
          }
          info {
            html
            text
            raw
          }
          favourite_kata {
            html
            text
            raw
          }
        }
      }
    }
  }
}
`

export default ({ data }) => {
  // Define the coaches content returned from Prismic
  const coaches = data.allPrismicCoach.edges.map(e => e.node);

  return(
    <Layout title="Coaches">
      <div className="pure-u-lg-3-5 pure-u-1-1 main">
        <h1>Coaching Team</h1>
        <div class="pure-g">
          <div className="pure-u-lg-3-5 pure-u-1-1 main">
            {coaches.map(coach => (
              <div>
                <h4>
                  <strong>{coach.data.title.text} {coach.data.name.text}</strong>
                  &nbsp;
                  {coach.data.rank.text}
                </h4>
                <p>{coach.data.info.text}</p>
                {coach.data.favourite_kata?.text && (
                  <p>Favourite kata: {coach.data.favourite_kata.text}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
